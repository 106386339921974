import { useStaticQuery, graphql } from 'gatsby'

export const usePageContent = () => {
    const {
        allContentfulSection: { nodes: nodesSection },
        allContentfulBlogPost: { nodes: nodesBlogsEntries },
        allContentfulSeo: { nodes: nodesMetaData },
    } = useStaticQuery(graphql`
        query QueryBlog {
            allContentfulSection(filter: { contentful_id: { eq: "40XAWtFQE2RpULw9VSHnvR" } }) {
                nodes {
                    title
                    node_locale
                }
            }

            allContentfulBlogPost(sort: { order: DESC, fields: createdAt }) {
                nodes {
                    createdBy
                    createdAt
                    slug
                    node_locale
                    title
                    articleText {
                        raw
                    }
                    miniatureImage {
                        file {
                            url
                        }
                    }
                    coverImage {
                        file {
                            url
                        }
                    }
                    readingTime
                    topic
                }
            }

            allContentfulSeo(filter: { contentful_id: { eq: "2MOy3DMDolAMyiay8EvErc" } }) {
                nodes {
                    title
                    url
                    description {
                        description
                    }
                    image {
                        file {
                            url
                        }
                    }
                    node_locale
                }
            }
        }
    `)

    return {
        nodesSection,
        nodesBlogsEntries,
        nodesMetaData,
    }
}
